body {
  font-family: 'Gotham', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
  line-height: 1.1;
  background: #cecece;
  color: white;
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/gotham-medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/gotham-bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/gotham-light.otf') format('opentype');
}
